import { Component } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Teramoment, IT services and consulting';

  ngOnInit() {
    $(document).ready(function(t){var a=[];t("section").each(function(){a.push(t(this).offset().top)}),t("#navigation ul li").click(function(){return t("html, body").animate({scrollTop:t(t.attr(this,"data-id")).offset().top},500),!1}),t("#navigation ul li").click(function(){t("#navigation ul li").removeClass("active"),t(this).addClass("active")}),t("#navigation ul li").hover(function(){t(this).find(".label").show()},function(){t(this).find(".label").hide()}),t(document).scroll(function(){for(var e,i=t(document).scrollTop(),n=0;n<a.length;n++)if(i<=a[n]){e=n;break}t("#navigation ul li").removeClass("active"),t("#navigation ul li:eq("+e+")").addClass("active")}),t("#navigation ul li").click(function(){t("#navigation ul li").removeClass("active"),t(this).addClass("active")}),t("form").submit(function(a){""===t("#g-recaptcha-response").val()&&(a.preventDefault(),t("#submit-contact").val("Accept reCAPTCHA and send message"));var e=t("#contact-message").val();if(e.length<20){var i=20-e.length;1==i?t("#submit-contact").val("Message requires at least "+i+" more character to be sent"):t("#submit-contact").val("Message requires at least "+i+" more characters to be sent")}})});
  }
}
